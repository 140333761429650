import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { EnvService } from '../utils/env.service';
import { constants } from '../../../config/app.constants';
import { PaymentItem } from '../../classes/payment-item';
import { Observable, of, throwError } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  observe: 'response'
};

@Injectable({
  providedIn: 'root'
})

export class PaymentHistoryService {
  private paymentCms: any;
  private paymentMade: any;

  constructor(private http: HttpClient,
    private env: EnvService) { }


  getPaymentsMade(codPro: number, numeroDias: number, filtroFechas: number, fechaDesde: string, fechaHasta: string, sucursal: any, cia: string = null) {
    // tslint:disable-next-line: indent
    const urlCmsPayment = constants.config.apiUrl + constants.config.getPaymentHistory +
      `?codigoProveedor=${codPro}&numeroDias=${numeroDias}&filtroFechas=${filtroFechas}` +
      `&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&sucursal=${sucursal ? sucursal.code : ''}&cia=${cia !== null ? cia : ''}`;
    this.paymentMade = this.http.get(urlCmsPayment);
    return this.paymentMade;
  }


  // Obtener informacion en historico de pago.
  getPaymentItems(data: any, validador: any, codigo: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();
    body.append('formData', JSON.stringify(data));
    
    body.append('validador', validador);
    body.append('codigo', codigo);
    const url = constants.config.apiUrl + constants.config.getPaymentHistory;
    return this.http.post<any>(url, body, { headers })
      .pipe(
        tap(_ => console.log('fetched paymentItems')),
        catchError(this.handleError)
      );
  }

  // Obtener informacion en detalle de historico de pago.
  getRelatedPaymentsById(id: string, tipoDoc: any, docCliente: any, dias: any, cliente: any, sucursal: any) {
    const json = { nro_docto: id, id_tipo_docto: tipoDoc, Id_Cliente: docCliente, dia: dias, cliente, sucursal };
    const url = constants.config.apiUrl + constants.config.getRelatedPaymentsForHistoryDetail;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();
    body.append('formData', JSON.stringify(json));

    return this.http.post<any>(url, body, { headers })
      .pipe(
        tap(_ => console.log('fetched paymentRelatedItems')),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.log('an error ocurred', error.error.message);
    } else {
      console.log('Backend error', error.status);
      console.log('client side error', error.error);
    }
    return throwError(
      'Smething wrong' + error.error
    );
  }


  /*getRetornarDias() {
    const url = this.env.apiGatewayFront;
    return this.http.get(`${url}/${constants.config.getRetornarDiasErpBackend}`).toPromise();
  }*/

  getRetornarDias(): Observable<HttpResponse<any>> {

    const url = constants.config.apiUrl + constants.config.getRetornarDiasErpBackend;
    return this.http.get<any>(url);
  }
}
