import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor() { }

  clearCacheAndLocalStorage() {
    const dateCleanedCache = localStorage.getItem('date_cleaned_cache');

    localStorage.clear();

    if (dateCleanedCache) {
      localStorage.setItem('date_cleaned_cache', dateCleanedCache);
    }

    caches.keys().then(names => {
      for (let name of names) {
        caches.delete(name);
      }
    });
  }
}
