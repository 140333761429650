import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { AuthService } from '../../../services/auth/auth.service';
import { PaymentHistoryService } from '../../../services/payment-history/payment-history.service';
import { BranchOfficeService } from '../../../services/branch-office/branch-office.service';
import { HeaderService } from '../../../services/header/header.service'
import { LoginService } from '../../../services/login/login.service';
import { first, timeout } from 'rxjs/operators';
import { HttpClientModule } from '@angular/common/http';
import { group } from 'console';
import { CacheService } from 'src/app/services/cache/cache.service';




@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  blogEvent: any;
  logo: string;
  jsonUser: any;
  nameUser: string;
  nameSupplier: string;
  showForm: false;
  diaErp: any;
  menuContent: object;
  public showConsolidatedWallet = 0;
  public showConsolidatedHistory = 0;
  // tslint:disable-next-line:no-input-rename
  @Input('b2sContent')
  public b2sContent: any;
  public branchOffice: string = null;
  public branchOfficeOptions = [];
  public subscription: Subscription;
  public showCupoMoraMessage: any;
  public CupoMoraMessage: any;
  public indCupo: any;
  public indMora: any;
  public serviceSuscription: Subscription;
  public collection_modality: string;

  // tslint:disable-next-line:variable-name
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private paymentHistoryService: PaymentHistoryService,
    private _branchOfficeService: BranchOfficeService,
    private _toastr: ToastrService,
    private headerService: HeaderService,
    private loginService: LoginService,
    private cacheService: CacheService
  ) {
    const json = {
      data: 20,
    };
    this.getLoginService(json);
    this.getMenuContent()
    const thirdBranch = localStorage.getItem('selectedSucursal');
    if (!thirdBranch || thirdBranch === 'undefined' || thirdBranch === '' || JSON.parse(thirdBranch) === null) {
      this.branchOffice = null;
    } else {
      this.branchOffice = JSON.parse(localStorage.getItem('selectedSucursal')).thirdId;
    }
    try {
      this.branchOfficeOptions = JSON.parse(localStorage.getItem('userData')).accounts;
    } catch (error) {
      this._authService.logoutService();
      this.cacheService.clearCacheAndLocalStorage();
    }
    this.indCupo = parseInt(localStorage.getItem('bloqueo_cupo'));
    this.indMora = parseInt(localStorage.getItem('bloqueo_mora'));
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {
    //this.logo = localStorage.getItem('logo');
    this.jsonUser = JSON.parse(localStorage.getItem('userData'));
    this.nameUser = this.jsonUser.contactFirstName;
    this.nameSupplier = this.jsonUser.thirdName;
    this.menuContent = {}
    this.getRetornarDias();
    this.subscription = this._branchOfficeService.getBranchOffice().subscribe(bo => {

      this.branchOffice = bo.thirdId;

      this.getBlockedStatus(bo.thirdId);
      this.setBlockedMessage();
    });

  }

  logout() {
    this._authService.logoutService();
    this.cacheService.clearCacheAndLocalStorage();
    this._router.navigate(['/login']);
  }

  getRetornarDias() {
    this.paymentHistoryService.getRetornarDias().subscribe(respuesta => {
      if (respuesta[`data`]) {
        this.diaErp = respuesta[`data`];
      } else {
        this.diaErp = 30;
      }
      localStorage.setItem('thisDiaErp', JSON.stringify(this.diaErp));
    });
  }

  onChangeThirdBranch(event) {
    let bo;
    this.branchOfficeOptions.map(element => {
      if (element.thirdId === this.branchOffice) {
        bo = element;
      }
    });
    localStorage.setItem('selectedSucursal', JSON.stringify(bo));
    this._branchOfficeService.setBranchOffice(bo);
    this._toastr.info('Cambio guardado con exito.', bo.thirdName);

    this.getBlockedStatus(bo.thirdId);
  }

  getBlockedStatus(thirdId) {

    this.serviceSuscription = this.headerService
      .getBlockedData(thirdId)
      .pipe(

    )
      .subscribe((response: any) => {
        this.indCupo = response.bloqueoCupo;
        this.indMora = response.bloqueoMora;
        this.setBlockedMessage();
        localStorage.setItem('bloqueo_cupo', this.indCupo);
        localStorage.setItem('bloqueo_mora', this.indMora);
      })
  }

  setBlockedMessage() {
    let selectedSucursal = JSON.parse(localStorage.getItem('selectedSucursal'));
    selectedSucursal = "thirdName" in selectedSucursal ? selectedSucursal.thirdName : null;

    this.showCupoMoraMessage = false;

    if (selectedSucursal === "Todas las sucursales")
      return;

    if ((this.indCupo + this.indMora) > 0) {
      this.showCupoMoraMessage = true;
      if (this.indCupo && this.indMora) {
        this.CupoMoraMessage = 'por Cupo y Mora!'
      }
      else if (this.indCupo) {
        this.CupoMoraMessage = 'por Cupo!'
      }
      else if (this.indMora) {
        this.CupoMoraMessage = 'por Mora!'
      }
    }
  }

  getLoginService(params) {

    this.loginService.getDataLogin(params).pipe(first(), timeout(5000)).subscribe(
      (response: any) => {

        if (response.error) {
          this.logo = localStorage.getItem('logo');
        } else {
          this.logo = response.logo;
        }
      }, (error: any) => {
        this.logo = localStorage.getItem('logo');
      }
    );
  }

  sortByOrder(a: any, b: any) {
    return a.value.order - b.value.order;
  }

  existContent(obj: any): boolean {
    return obj !== null && Object.keys(obj).length > 0;
  }

  getMenuContent() {
    this.headerService.getMenuData().subscribe(
      (response: any) => {
        this.menuContent = response.menuContent

        // Subitems sorted
        Object.keys(this.menuContent).forEach((key) => {
          let valorActual = this.menuContent[key]["data"];
          valorActual.sort((a: any, b: any) => a.order - b.order);
          this.menuContent[key]["data"] = valorActual;
        })
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  redirectToExternalURL(itemObj) {
    let externalURL = itemObj.data.filter(item => item.type == "ext")[0].value
    window.open(externalURL, '_blank');
  }

  get collectionModality() {
    return localStorage.getItem('collection_modality') === "1"
  }

}
